<template>
  <div class="block_page" style="text-align: center;margin: 2vh 0;">
    <!-- <span class="demonstration">完整功能</span> -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-sizes="[10, 20, 30, 50, 100]"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper',
    },
    pageSize: {
      type: Number,
      require: true,
      default: () => 30,
    },
    currentPage: {
      type: Number,
      require: true,
      default: () => 1,
    },
    total: {
      type: Number,
      require: true,
    },
  },
  computed: {
    // currentPage: {
    //   get() {
    //     return this.currentPage;
    //   },
    //   set(val) {
    //     this.$emit('update:currentPage', val);
    //   },
    // },
    // pageSize: {
    //   get() {
    //     return this.limit;
    //   },
    //   set(val) {
    //     this.$emit('update:limit', val);
    //   },
    // },
  },
  methods: {
    handleSizeChange(val) {
      this.$emit('pagination', { page: 1, limit: val });
    },
    handleCurrentChange(val) {
      this.$emit('pagination', { page: val, limit: this.pageSize });
    },
  },
};
</script>
