import {request} from '../index';

//专家接口配置
export function getPage(url, params) {
  return request({
    method: 'GET',
    url: 'aps' + url,
    params: params
  });
}

export function postSave(url, data) {
  return request({
    method: 'POST',
    url: 'aps' + url,
    data
  });
}

export function ptglDelete(url) {
  return request({
    method: 'DELETE',
    url: 'aps' + url
  });
}
