<template>
  <div class="enterpriseInfo">
    <div class="search">
      <el-form ref="searchForm" :inline="true" :model="searchForm" label-width="100px">
        <el-form-item label="平台名称" v-if="false">
          <el-input v-model="searchForm.ptmc" placeholder="请输入平台名称"></el-input>
        </el-form-item>
        <el-form-item label="项目名称">
          <el-select v-model="searchForm.xmmc" clearable placeholder="请选择平台名称">
            <el-option v-for="item in xmList" :key="item.id" :label="item.xmmc" :value="item.xmmc"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="项目类型">
          <el-select v-model="searchForm.xmlx" clearable placeholder="请选择项目类型">
            <el-option label="标注项目" value="1"></el-option>
            <el-option label="采集项目" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期">
          <el-date-picker
              v-model="rzrq"
              type="daterange"
              align="right"
              unlink-panels
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="员工姓名">
          <el-input v-model="searchForm.ygxm" placeholder="请输入员工姓名"></el-input>
        </el-form-item>
        <el-form-item label="员工账号">
          <el-input v-model="searchForm.ygzh" placeholder="请输入员工账号"></el-input>
        </el-form-item>
        <el-form-item label=" ">
          <el-button @click="getUserList">查询</el-button>
          <el-button type="primary" @click="exportToExcel">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="table_box">
      <el-table stripe border class="table" :data="tableData" height="600" style="width: 100%">
        <el-table-column label="序号" type="index" fixed width="50"></el-table-column>
        <el-table-column prop="ptmc" label="平台名称" fixed align="center"></el-table-column>
        <el-table-column prop="xmmc" label="项目名称" fixed align="center"></el-table-column>
        <el-table-column prop="ygxm" label="员工姓名" fixed align="center"></el-table-column>
        <el-table-column prop="ygzh" label="员工账号" fixed align="center"></el-table-column>
        <el-table-column prop="rzrq" label="日报日期" fixed align="center"></el-table-column>
        <el-table-column prop="zxq" label="总需求" align="center"></el-table-column>
        <el-table-column prop="rwl" label="任务流" align="center"></el-table-column>
        <el-table-column prop="sjbid" label="数据包ID" align="center"></el-table-column>
        <el-table-column prop="tmid" label="题目ID" align="center"></el-table-column>
        <el-table-column prop="zs" label="帧数" align="center"></el-table-column>
        <el-table-column prop="zb2d" label="2D" align="center"></el-table-column>
        <el-table-column prop="zb3d" label="3D" align="center"></el-table-column>
        <el-table-column prop="wcny" label="完成内业" align="center"></el-table-column>
        <el-table-column prop="wcwy" label="完成外业" align="center"></el-table-column>
        <el-table-column prop="rzb" label="日指标" align="center"></el-table-column>
        <el-table-column prop="zbwcl" label="日指标完成率" align="center"></el-table-column>
        <el-table-column prop="ywdj" label="业务单价" align="center"></el-table-column>
        <el-table-column prop="rcz" label="日产值" align="center"></el-table-column>
      </el-table>
      <div class="table_pagination">
        <pagination :total="total" :currentPage="searchForm.page" :pageSize="searchForm.size" @pagination="pageChange"/>
      </div>
    </div>
  </div>
</template>
<script>
import pagination from '@/components/pagination.vue';
import {getPage} from '@/request/api/ptgl.js';
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';

export default {
  components: {pagination},

  data() {
    return {
      tableData: [],
      lists: [],
      xmList: [],
      total: 0,
      searchForm: {
        size: 10
      },
      rzrq: '',
      loading: true,
      // 菜单禁用
      infoState: false,
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      }
    };
  },
  watch: {},
  mounted() {
    this.getUserList();
    this.getXmList();
  },
  methods: {
    // 获取用户列表
    getUserList() {
      this.lists = [['序号', '平台名称', '员工姓名', '员工账号', '日报日期', '任务流', '数据包ID', '题目ID', '帧数', '2D', '3D', '完成内业', '完成外业', '日指标', '日指标完成率', '业务单价', '日产值']];
      if (this.rzrq) {
        this.searchForm.sdate = this.rzrq[0];
        this.searchForm.edate = this.rzrq[1];
      }
      getPage('/pro11/ygrb/pagetz', {
        page: 1,
        size: 10,
        ...this.searchForm
      }).then((res) => {
        if (res.data.state == 'success') {
          this.tableData = res.data.data;
          this.total = res.data.size;
          for (let a = 0; a < this.tableData.length; a++) {
            this.lists.push([a + 1, this.tableData[a].ptmc, this.tableData[a].ygxm, this.tableData[a].ygzh, this.tableData[a].rzrq, this.tableData[a].rwl, this.tableData[a].sjbid, this.tableData[a].tmid, this.tableData[a].zs, this.tableData[a].zb2d, this.tableData[a].zb3d, this.tableData[a].wcny, this.tableData[a].wcwy, this.tableData[a].rzb, this.tableData[a].zbwcl, this.tableData[a].ywdj, this.tableData[a].rcz])
          }
        }
      });
    },
    getXmList() {
      getPage('/pro11/xmgl/list').then((res) => {
        if (res.data.state == 'success') {
          this.xmList = res.data.data;
        }
      });
    },

    pageChange({page, limit}) {
      this.searchForm.page = page;
      this.searchForm.size = limit;
      this.getUserList();
    },
    exportToExcel() {
      // 将数据转换为工作表
      const worksheet = XLSX.utils.aoa_to_sheet(this.lists);
      console.log("导出：", this.lists)
      console.log(typeof (this.lists))
      // 创建工作簿并添加工作表
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      // 生成Excel文件
      const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
      // 使用blob和FileReader来处理二进制数据
      const dataBlob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
      // const dataUrl = URL.createObjectURL(dataBlob);
      console.log(dataBlob);

      // 使用saveAs来下载文件
      saveAs(dataBlob, this.xlsxName);

    },

    /**
     * @description: loadingg 关闭
     * @param {*}
     */

    exitLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }
};
</script>
<style scoped lang="less">
@import '~@/style/colorPublic';

.butColor {
  // background: @but_color;
  background: #409eff;
}

.enterpriseInfo {
  background: #fff;
  padding: 2vh;
  box-sizing: border-box;
}

.operation {
  display: flex;
  justify-content: center;

  span {
    cursor: pointer;
    margin-right: 1vh;
  }
}

.table {

  overflow-y: auto;
}

.pageStyle {
  text-align: @pageAlign;
  margin-top: 1vh;
}

.search {
  display: flex;
  align-items: center;
  margin-bottom: 1vh;
}

.searchForm {
  display: flex;
  position: relative;
}

.searchForm /deep/ .el-form-item {
  margin-bottom: 0;
}

.searchForm /deep/ .el-input__inner {
  // height: 4vh !important;
  height: 30px !important;
  line-height: 4vh !important;
}

.searchForm /deep/ .el-button--default {
  // height: 4vh !important;
  height: 30px !important;
  padding: 9px 19px;
}

.accept {
  cursor: pointer;
  background: #3366cc;
  color: #fff;
  border-radius: 5px;
  padding: 0.5vh;
  box-sizing: border-box;
}

// .search{
//     border: 1px solid #ccc;
//     padding: 1vh;
//     box-sizing: border-box;
// }
.search {
  display: flex;
  align-items: center;
  margin-bottom: 1vh;
  // border: 1px solid #ccc;
  padding: 1vh;
  box-sizing: border-box;
}

.searchForm {
  display: flex;
  position: relative;
}

.searchForm /deep/ .el-form-item {
  margin-bottom: 0;
}

.receip /deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 10vw;
  height: 10vw;
  line-height: 10vw;
}

.receip /deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.receip /deep/ .avatar-uploader-icon {
  color: #8c939d;
  width: 10vw;
  height: 10vw;
  line-height: 10vw;
  text-align: center;
}

.receip /deep/ .avatar {
  width: 10vw;
  height: 10vw;
  display: block;
}

@media screen and (max-width: 1500px) {
  .searchForm /deep/ .el-input__inner {
    height: 5vh !important;
    width: 380px;
    line-height: 5vh !important;
  }

  .searchForm /deep/ .el-button--default {
    height: 5vh !important;
    padding: 9px 19px;
  }

  .searchBut {
    position: absolute;
    right: 0;
    top: 0;
  }

  .table {
    overflow-y: auto;
  }
}
</style>
